<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div class="i-modal__inner">
        <div class="base-modal__left-side">
          <div class="base-modal-header">
            <span class="base-modal-title">Новая заявка</span>
          </div>
          <div class="base-modal-info">
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-flag/>
                <span>Тип</span>
              </div>
              <div class="base-modal-info__value">
                <div class="tags-select">
                  <div v-for="issueType in issueTypes"
                       :key="issueType.id"
                       @click="payload.type = issueType.id"
                       class="tags-select__item"
                       :class="{
                         'selected': payload.type === issueType.id,
                         'tags-select__item--error': $v.payload.type.$error
                       }">
                    {{ issueType.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-link/>
                <span>Связанные обращения</span>
              </div>
              <div class="base-modal-info__value">
                <v-select
                  class="vue-select-input"
                  v-model="appealsForLink"
                  :options="appealsList"
                  :reduce="appeal => appeal.id"
                  :get-option-label="(option) => option.id + ', ' + option.address.street"
                  multiple
                  placeholder="Выберите из списка">
                  <template #option="option">
                    <div class="issue-select-option">
                      <div class="issue-select-option__id">
                        {{ option.id }}
                      </div>
                      <div class="issue-select-option__address">
                        {{ option.address.street }}, {{ option.address.house }}
                      </div>
                      <div class="issue-select-option__time">
                        {{ $moment(option.created_at).fromNow() }}
                      </div>
                      <div class="issue-select-option__checkbox">
                        <label class="base-checkbox base-checkbox--squared">
                          <input
                            :checked="payload.appeals_array.includes(option.id)"
                            type="radio">
                          <span/>
                        </label>
                      </div>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-link/>
                <span>Связанная заявка</span>
              </div>
              <div class="base-modal-info__value">
                <v-select
                  class="vue-select-input"
                  v-model="payload.parent_issue_id"
                  :options="issuesList"
                  :reduce="issue => issue.id"
                  :get-option-label="(option) => option.id + ', ' + option.address.street"
                  placeholder="Выберите из списка">
                  <template #option="option">
                    <div class="issue-select-option">
                      <div class="issue-select-option__id">
                        {{ option.id }}
                      </div>
                      <div class="issue-select-option__address">
                        {{ option.address.street }}, {{ option.address.house }}
                      </div>
                      <div class="issue-select-option__time">
                        {{ $moment(option.created_at).fromNow() }}
                      </div>
                      <div class="issue-select-option__checkbox">
                        <label class="base-checkbox base-checkbox--squared">
                          <input
                            :checked="payload.parent_issue_id === option.id"
                            type="radio">
                          <span/>
                        </label>
                      </div>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-tag-cross/>
                <span>Структ. подразделение</span>
              </div>
              <div class="base-modal-info__value">
                <v-select
                  v-model="payload.structural_unit_id"
                  class="vue-select-input"
                  :options="structuralUnitsList"
                  :reduce="unit => unit.id"
                  :get-option-label="(option) => option.name"
                  placeholder="Выберите из списка"
                  :disabled="isStructuralUnitSelectDisabled"
                />
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-info-circle/>
                <span>Проблема</span>
              </div>
              <div class="base-modal-info__value">
                <problem-category-selector
                  :category_id="payload.category_id"
                  :group="group_id"
                  @update:category_id="categoryId => payload.category_id = categoryId"
                  @update:group_id="groupId => group_id = groupId"/>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-clipboard/>
                <span>Основание</span>
              </div>
              <div class="base-modal-info__value">
                <input
                  type="text"
                  v-model="$v.payload.based_on.$model"
                  class="base-input base-input--bordered"
                  :class="{'base-input--error': $v.payload.based_on.$error}"
                  placeholder="Введите основание заявки"
                >
              </div>
            </div>
            <div
              v-if="payload.type !== 1"
              class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-clock />
                <span>Плановый срок</span>
              </div>
              <div class="base-modal-info__value">
                <div class="range-date-picker">
                  <date-picker
                    v-model="payload.planned_start_date"
                    :default-value="defaultValue"
                    placeholder="Начало"
                    type="datetime"
                    :minuteStep="10"
                    format="DD.MM.YYYY HH:mm"
                    value-type="YYYY-MM-DD HH:mm"
                    class="base-date-picker"
                  />
                  <div class="range-date-picker__dash"/>
                  <date-picker
                    v-model="payload.planned_end_date"
                    :default-value="defaultValue"
                    placeholder="Окончание"
                    type="datetime"
                    :minuteStep="10"
                    format="DD.MM.YYYY HH:mm"
                    value-type="YYYY-MM-DD HH:mm"
                    class="base-date-picker"
                    :disabled-date="notBeforeStartDate"
                  />
                </div>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-pin/>
                <span>Адрес</span>
              </div>
              <div class="base-modal-info__value">
                <div class="vue-dadata-wrapper">
                  <dadata-suggestions
                    class="vue-dadata-input"
                    type="ADDRESS"
                    @change="setAddress"
                    @input="handelAddressInput"
                    placeholder="Начните вводить адрес"
                    v-model="address"
                  />
                </div>
              </div>
            </div>
            <manual-address-form
              :address_flat="payload.address_flat"
              :address_block="payload.address_block"
              :address_house="payload.address_house"
              :address_street="payload.address_street"
              :address_settlement="payload.address_settlement"
              :address_city="payload.address_city"
              :address_region="payload.address_region"
              @update:address_flat="value => payload.address_flat = value"
              @update:address_block="value => payload.address_block = value"
              @update:address_house="value => payload.address_house = value"
              @update:address_street="value => payload.address_street = value"
              @update:address_settlement="value => payload.address_settlement = value"
              @update:address_city="value => payload.address_city = value"
              @update:address_region="value => payload.address_region = value"
            />
            <div class="base-modal-info__item--border-bottom create-issue-map">
              <create-issue-modal-map @update:coords="({lat, lng}) => {
                payload.address_lat = lat;
                payload.address_lon = lng;
              }"/>

              <div v-if="$v.payload.address_lat.$error"
                   class="error-message">
                Не указаны координаты, поставьте метку на карте
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-comment/>
                <span>Комментарий</span>
              </div>
              <div class="base-modal-info__value">
                <textarea
                  type="text"
                  v-model="payload.description"
                  class="base-textarea"
                  placeholder="Напишите комментарий"
                />
              </div>
            </div>
            <div class="base-modal-btn-wp create-issue-buttons">
              <div @click="submitForm()"
                   class="button button--mode-primary">
                <span>Создать</span>
              </div>
              <div @click="cancelIssueCreate()"
                   class="button button--mode-outline">
                <span>Отменить</span>
              </div>
            </div>
          </div>
        </div>
        <div class="i-modal-btn-wp i-modal-btn-wp--back">
          <div class="task-modal__mobile-actions">
            <button @click="$store.dispatch('baseSideModal/goBack')"
                    class="button button--mode-outline task-modal__mobile-back">
              <icon-arrow-left/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import IconLink from "@/components/Icons/IconLink.vue";
import IconInfoCircle from "@/components/Icons/IconInfoCircle.vue";
import CreateIssueModalMap from "@/components/ServiceDesk/Issues/CreateIssueModalMap.vue";
import IconFlag from "@/components/Icons/IconFlag.vue";
import ProblemCategorySelector from "@/components/ServiceDesk/Issues/ProblemCategorySelector.vue";
import IconPin from "@/components/Icons/IconPin.vue";
import IconClipboard from "@/components/Icons/IconClipboard.vue";
import IconComment from "@/components/Icons/IconComment.vue";
import Vue from "vue";
import DadataSuggestions from "vue-dadata-suggestions";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import {mapGetters, mapState} from "vuex";
import AppealState from "@/models/callCenter/AppealState";
import IssueState from "@/models/serviceDesk/IssueState";
import {showAlert, showToast} from "@/utils/notification";
import IconClock from "@/components/Icons/IconClock.vue";
import DatePicker from "vue2-datepicker";
import ManualAddressForm from "@/components/ServiceDesk/ManualAddressForm.vue";
import IconTagCross from "@/components/Icons/IconTagCross.vue";

export default {
  name: "CreateIssueModalContent",
  components: {
    IconTagCross,
    ManualAddressForm,
    IconClock,
    IconComment,
    IconClipboard,
    IconPin,
    ProblemCategorySelector,
    IconFlag,
    CreateIssueModalMap,
    IconInfoCircle,
    IconLink,
    IconArrowLeft,
    DatePicker
  },
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        type: null,
        appeals_array: [],
        parent_issue_id: null,
        category_id: null,
        based_on: null,
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        address_lat: null,
        address_lon: null,
        description: null,
        planned_start_date: null,
        planned_end_date: null,
        structural_unit_id: null
      },
      group_id: null,
      address: null,
      appealsForLink: [],
      autoFillAddress: true,
      defaultValue: new Date(),
      isStructuralUnitSelectDisabled: false
    }
  },
  validations: {
    payload: {
      type: {required},
      based_on: {required},
      address_lat: {required},
      address_lon: {required}
    }
  },
  computed: {
    ...mapState({
      appealsList: state => state.appeals.unallocatedAppeals,
      issuesList: state => state.issues.relatedIssuesList,
      issueTypes: state => state.issueTypes.types,
      error: state => state.issues.error,
      componentPropsData: state => state.baseSideModal.componentPropsData,
      structuralUnitsList: state => state.structuralUnits.userStructuralUnitsList,
      currentUser: state => state.auth.user
    }),
    ...mapGetters({
      getAppeal: 'appeals/getAppealById',
      dadataLocation: 'projectConfig/getProjectLocation'
    })
  },
  watch: {
    'payload.parent_issue_id': function (id) {
      this.payload.based_on = `Заявка #${id}`
    },
    appealsForLink: function (appeals) {
      this.payload.appeals_array = appeals;

      if (this.autoFillAddress) {
        if (appeals.length) {
          const [appealId] = appeals;
          this.fillFieldsFromAppealData(appealId);
        } else {
          this.group_id = null;
          this.address = null;

          this.payload.address_region = null;
          this.payload.address_region = null
          this.payload.address_city = null
          this.payload.address_settlement = null
          this.payload.address_street = null
          this.payload.address_house = null
          this.payload.address_block = null
          this.payload.address_flat = null
          this.payload.address_lat = null
          this.payload.address_lon = null
          this.payload.based_on = null
          this.payload.appeals_array = []
          this.payload.category_id = null
          this.payload.description = null;
          this.payload.structural_unit_id = this.currentUser.attributes.structural_unit.id;
          this.isStructuralUnitSelectDisabled = false;
        }
      }
    }
  },
  mounted() {
    Vue.use(DadataSuggestions, {
      token: process.env.VUE_APP_DADATA_API_KEY,
      constraints: {
        locations: this.dadataLocation
      }
    });

    this.payload.structural_unit_id = this.currentUser.attributes.structural_unit.id;

    if (this.componentPropsData.relatedIssueId) {
      const issueId = this.componentPropsData.relatedIssueId;

      if (issueId > 0) {
        this.payload.based_on = `Заявка #${issueId}`;
        this.payload.parent_issue_id = issueId;
      } else {
        this.payload.based_on = null;
        this.payload.parent_issue_id = null;
      }
    }

    if (this.componentPropsData.relatedAppealId) {
      const appealId = this.componentPropsData.relatedAppealId;
      this.fillFieldsFromAppealData(appealId)
    }

    this.$store.dispatch('structuralUnits/getUserStructuralUnitsList');
    this.$store.dispatch('appeals/getAppealsByStatus', AppealState.NEW);
    this.$store.dispatch('issues/getIssuesForLink', {
      issue_states: [
        IssueState.NEW,
        IssueState.IN_WORK
      ].join()
    });
  },
  methods: {
    setAddress(i) {
      if (i.data) {
        this.payload.address_region = i.data.region;
        this.payload.address_city = i.data.city;
        this.payload.address_settlement = i.data.settlement_with_type;
        this.payload.address_street = i.data.street_with_type;
        this.payload.address_house = i.data.house;
        this.payload.address_block = i.data.block;
        this.payload.address_flat = i.data.flat;

        if (!this.payload.address_lat && !this.payload.address_lon) {
          this.payload.address_lat = parseFloat(i.data.geo_lat);
          this.payload.address_lon = parseFloat(i.data.geo_lon);

          this.$root.$emit('fit-bounds-map', {
            coords: [parseFloat(i.data.geo_lat), parseFloat(i.data.geo_lon)],
            qc_geo: i.data.qc_geo
          })
        }
      }
    },
    handelAddressInput(value) {
      this.autoFillAddress = !value;
    },
    fillFieldsFromAppealData(appealId) {
      const appeal = this.getAppeal(appealId);
      this.isStructuralUnitSelectDisabled = true;

      this.address = appeal.getFullAddress();
      this.group_id = appeal.category.group_id;

      this.payload.category_id = appeal.category.id;
      this.payload.address_region = appeal.address.region;
      this.payload.address_city = appeal.address.city;
      this.payload.address_settlement = appeal.address.settlement;
      this.payload.address_street = appeal.address.street;
      this.payload.address_house = appeal.address.house;
      this.payload.address_block = appeal.address.block;
      this.payload.address_flat = appeal.address.flat;
      const [lat, lon] = appeal.coordinates;
      this.payload.address_lat = lat;
      this.payload.address_lon = lon;

      this.payload.based_on = `Обращение #${appeal.id}`;
      this.payload.description = appeal.message;
      this.payload.structural_unit_id = appeal.structuralUnit.id

      if (this.componentPropsData.relatedAppealId) {
        this.payload.appeals_array = [this.componentPropsData.relatedAppealId];
      }

      this.$root.$emit('fit-bounds-map', {
        coords: [lat, lon],
        qc_geo: 1
      })
    },
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        this.$v.$reset();
        this.$store.dispatch('issues/createIssue', this.payload)
          .then(() => {
            this.$store.dispatch('issues/getIssues');
            this.$store.dispatch('baseSideModal/goBack');
            showToast('Заявка создана', 'success');
          })
          .catch(() => {
            showAlert('Ошибка', this.error, 'error');
          })
      }
    },
    cancelIssueCreate() {
      this.$v.$reset();
      this.$store.dispatch('baseSideModal/goBack');

      this.group_id = null;
      this.address = null;
      this.payload = {
        type: null,
        appeals_array: [],
        parent_issue_id: null,
        based_on: null,
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        address_lat: null,
        address_lon: null,
        description: null,
        planned_start_date: null,
        planned_end_date: null
      }
    },
    notBeforeStartDate(date) {
      let startDate = new Date(this.payload.planned_start_date);
      startDate.setDate(startDate.getDate() - 1);
      return date < startDate;
    }
  }
}
</script>

<style lang="scss" scoped>
.create-issue-buttons {
  display: flex;
  gap: 12px;

  & .button {
    width: 50%;
  }
}

.base-modal-info__item {
  align-items: baseline;
}

.create-issue-map {
  margin-top: 20px;
}
</style>
